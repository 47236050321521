.react-select-container {
  .react-select__control {
    color: $input-color;
    background-color: $input-bg;
    border: 0;
    border-radius: 0;
  }

  .react-select__input-container,
  .react-select__single-value {
    color: $input-color;
  }

  .react-select__menu {
    z-index: 5;
    color: $input-color;
    background-color: $input-bg;
    border-radius: 0;
  }
}

.btn-toggle-filters {
  display: none;
}

.attestation-filters {
  display: flex;
  align-items: flex-end;
  gap: 1.25rem;
  margin-bottom: 2.25rem;

  > * {
    flex-basis: 300px;
  }

}

.attestation-count {
  margin-bottom: 1.5rem;
}

@include media-breakpoint-down(xl) {

  .attestation-filters {

    > * {
      flex-grow: 1;
      flex-basis: 0;
    }

  }

}

@include media-breakpoint-down(xl) {

  .btn-toggle-filters {
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: 0;
    border: 0;
    background-color: transparent;
    font-family: Montserrat, sans-serif;
    color: $white;

    i {
      font-size: 1.25rem;
    }

    i.uil-times {
      display: none;
    }

    &.opened {

      i.uil-times {
        display: block;
      }

      i.uil-filter {
        display: none;
      }

    }

  }

  .attestation-filters {
    display: none;

    &.opened {
      display: flex;
    }

  }

  .attestation-count {
    display: none;

    &.opened {
      display: block;
    }

  }

}

@include media-breakpoint-down(md) {

  .attestation-filters {
    flex-direction: column;
    align-items: stretch;
  }

}

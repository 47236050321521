.spinner-overlay {
  position: fixed;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0, .7);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-overlay #spinner {
  position: relative;
}

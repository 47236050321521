.modal-header,
.modal-body {
  padding: 1.75rem 1.75rem;
}

.modal-header {

  .btn-close {
    filter: brightness(0) invert(1);
  }

  .unite-header {
    flex-grow: 1;
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    font-family: Montserrat, sans-serif;

    .title {
      @include font-size(1.8rem);
      font-weight: 700;
    }

    .sub-title {
      @include font-size(1.25rem);
      font-weight: 200;
    }

    .navigator {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

}

#unite_title{
  color: #fff;
  text-align: left;
  font-size: 2rem;
  line-height: 33px;
}

#unite_title .elearning{
  font-weight: 700;
}

#unite_title .chapitre{
  font-weight: 100;
  font-size: 1.5rem;
  line-height: 2rem;
}

#unite_footer{
  width: auto!important;
  position: absolute;
  right: 8rem;
}


// Code chelou a revoir sans doute...
#content_player {
  width: 100%!important;
  text-align: -webkit-center;
}

#mp4-player {
  width: 100%!important;
  height: 100%!important;
}

#mp4-player audio,
#content_player audio {
  height: 30vh!important;
}

#content_player object,
#content_player > div {
  width: 100%!important;
  height: calc(100vh - 200px)!important;
}


@include media-breakpoint-down(lg) {

  .modal-header,
  .modal-body {
    padding: 1rem 1.25rem;
  }

  .modal-header {

    .unite-header {

      .title {
        @include font-size(1.25rem);
      }

      .sub-title {
        @include font-size(1rem);
      }

    }

  }

}

@include media-breakpoint-down(lg) {


  .modal-header {

    .unite-header {
      flex-direction: column;
      align-items: flex-start;
    }

  }


}

/**
 * Bootstrap variables
 */

// Colors

$primary: #006fff;
$secondary: #212730;
$white: #fff;
$black: #151c25;

$dark-grey: #1e2e45;

$danger: #cc493c;
$warning: #ffb13b;
$success: #3ea279;

// Options

$enable-rounded: false;

// Body

$body-bg: $secondary;
$body-color: $white;

// Typography

$font-family-sans-serif: "Work Sans", system-ui, -apple-system, "Segoe UI", roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-font-family: "Guyhoquet";

$headings-color: $white;
$headings-line-height: 1.2;
$headings-font-weight: 700;

$h1-font-size: 2.5rem;
$h2-font-size: 2.0625rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.4375rem;
$h5-font-size: 1.1875rem;

// Containers

$container-padding-x: 4rem;

// Cards

$card-bg: #000;

// Forms

$input-bg: #3a4048;
$input-color: #ccc;

// Navs

$nav-link-color: $white;

// Modals

$modal-content-border-width: 0;
$modal-backdrop-bg: #000;
$modal-content-bg: $black;

// Tables

$table-bg: $body-bg;

// Pagination

$pagination-bg: $body-bg;
$pagination-focus-bg: $black;
$pagination-hover-bg: $black;
$pagination-active-color: $white;
$pagination-active-bg: $black;
$pagination-active-border-color: $white;
$pagination-disabled-bg: $body-bg;

// Accordion

$accordion-border-width: 0;
$accordion-bg: $dark-grey;
$accordion-button-active-bg: $dark-grey;

/**
 * Project variables
 */

$nav-width: 65px;
$nav-mobile-height: 70px;

h1,
h2,
h3,
h4,
h5 {

  &.plus {
    position: relative;
    padding-left: 1em;

    &::before {
      position: absolute;
      left: 0;
      width: .65em;
      height: .65em;
      top: .2em;
      content: " ";
      background-color: #006fff;
      mask-image: url("../../assets/icons/Plus.svg");
      mask-repeat: no-repeat;
      mask-size: .65em .65em;
      mask-position: 0 0;
    }

  }

}

h4 {
  font-weight: 600;
}

h5 {
  font-family: Montserrat, sans-serif;
}


/**
Horizontal rules
 */
.hr {
  @extend hr;
}

/**
Hr text
 */
.hr-text {
  display: flex;
  align-items: center;
  height: 1px;
  margin: 2rem 0;
  font-size: $h6-font-size;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: .04em;

  &::after,
  &::before {
    flex: 1 1 auto;
    height: $hr-height;
    background-color: currentcolor;
    opacity: .2;
  }

  &::before {
    margin-right: .5rem;
    content: "";
  }

  &::after {
    margin-left: .5rem;
    content: "";
  }

  > *:first-child {
    padding-right: .5rem;
    padding-left: 0;
    color: $text-muted;
  }

  &.hr-text-left {
    &::before {
      content: none;
    }

    & > *:first-child {
      padding-right: .5rem;
      padding-left: .5rem;
    }
  }

  &.hr-text-right {
    &::before {
      content: "";
    }

    &::after {
      content: none;
    }

    & > *:first-child {
      padding-right: 0;
      padding-left: .5rem;
    }
  }

  .card > & {
    margin: 0;
  }
}

.hr-text-spaceless {
  margin: -.5rem 0;
}

@include media-breakpoint-down(xl) {

  h1 {
    @include font-size(1.75rem);
  }

  h2 {
    @include font-size(1.3125rem);
  }

  h3 {
    @include font-size(1.3125rem);
  }

}

@include media-breakpoint-down(md) {

  h1 {
    @include font-size(1.3125rem);
  }

  h2 {
    @include font-size(1rem);
  }

  h3 {
    @include font-size(1rem);
  }

}

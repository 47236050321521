.search-head {
  width: 700px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3.5rem;

  img.logo {
    width: auto;
    height: 200px;
    margin-bottom: 1.25rem;
  }

  .btn-reset {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: $white;
    z-index: 1;
    font-size: 2rem;
  }

  .search-input{
    margin-bottom: 1.875rem;

    input.form-control {
      border: 3px solid #808080 !important;
      border-radius: 0;
      background-color: transparent !important;
      padding: 1.5rem;
      font-size: 1.25rem !important;

      &::placeholder {
        color: $white;
      }

    }

  }

  .title {
    position: relative;
    text-transform: uppercase;
    font-size: 1rem;

    &::after {
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 40px;
      height: 3px;
      content: "";
      background-color: rgb(13, 88, 226);
      border-radius: 5px;
    }

  }

  .ctas {
    display: flex;
    align-items: center;
    gap: .75rem;

    .btn {
      font-family: Guyhoquet, sans-serif;
      flex-grow: 1;
      flex-basis: 0;
      white-space: nowrap;
    }

  }

}

@include media-breakpoint-down(xl) {

  .search-head {
    margin-bottom: 3rem;
    padding-top: 1rem;

    .logo {
      display: none;
    }

    .btn-reset {
      top: .5rem;
    }

    .search-input{

      input.form-control {
        padding: 1.125rem;
        font-size: 1rem !important;
      }

    }

    .ctas {
      flex-grow: 1;
    }

  }

}

@include media-breakpoint-down(md) {

  .search-head {

    .search-input {
      margin-bottom: 1.25rem;
    }

  }

}

.rmsc .dropdown-container{
    background-color: unset;
    border: 0px!important;
}

.dropdown-content *{
    background-color: #212730!important;
}
.themeSelect .dropdown-container{
    background-color: #3A4048 !important;
}


.banner-profile {
  padding: 1.5rem;
  display: flex;
  gap: 2rem;

  .banner-profile-body {
    flex-grow: 1;
    padding-top: 3rem;
  }

  &.firstColor {
    background: linear-gradient(90deg, rgba(1, 111, 253, 1) 0%, rgba(17, 57, 108, 1) 62%, rgba(30, 46, 68, 1) 100%);
  }

  &.secondColor {
    background: #151c25;
  }

  .firstColorCadre {
    background: #151c25;
  }

  .secondColorCadre {
    background: #1e2e45;
  }

  .firstColorCadre:hover {
    background: #006fff;
  }

  .secondColorCadre:hover {
    background: #006fff;
  }

}

@include media-breakpoint-down(xl) {

  .banner-profile {

    .banner-profile-head {
      display: none;
    }

    .banner-profile-body {
      padding-top: 0;
    }

  }

}

@include media-breakpoint-down(md) {

  .banner-profile {

    .image-profile {
      width: 90px;
    }

  }

}

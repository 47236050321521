.unite_icone {
  margin-right: .5rem;
}

.card .card-title {
  font-size: 16px !important;
}

.titre-methode-vente p {
  font-size: 16px;
  color: var(--bs-white);
  font-weight: 400;
}

.card-img-top {
  border-radius: 0 !important;
}
.body-details .search-title img {
  width: 18px;
}

.textBox {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: white;
}
.bottomRight {
  /* position: absolute; */
  bottom: 0;
  /* right: 50px;
  float:right; */
}

@media screen and(max-width:1024px) {
  .body-details .rest {
    flex-direction: column !important;
  }
  .body-details .chartContainer {
    max-width: 67% !important;
  }
  .body-details .imgDetail {
    width: 100% !important;
  }
  .body-details .search-title {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 991px) {
  .body-details {
    background: linear-gradient(180deg, #151c25 86%, #151c2500 30%, rgba(9, 9, 121, 0) 63%);
  }
}
@media only screen and (max-width: 768px) {
  .body-details {
    background: linear-gradient(180deg, #151c25 86%, #151c2500 30%, rgba(9, 9, 121, 0) 63%);
  }
}

.thematic-carousel-wrapper {

  h3 {
    padding-right: 5rem;
  }

}

.thematic-carousel {
  position: relative;

  .splide {
    width: calc(100% + 2rem);
    margin-right: -2rem;

    &:not(.is-overflow) {

      .splide__arrows {
        display: none;
      }

    }

    .splide__arrows {

      &.splide__arrows--ltr {
        position: absolute;
        top: -41px;
        right: 2rem;
        width: 110px;
      }

      .splide__arrow.splide__arrow--prev,
      .splide__arrow.splide__arrow--next {
        width: 35px;
        height: 35px;
        padding: 9px !important;
        line-height: .4 !important;
        border: 1px solid var(--bs-primary) !important;
        border-radius: 0;

        &:not(:disabled) {
          background: $white;
          opacity: 1;
        }

        svg {
          fill: $primary;
        }

        &:hover {
          background-color: $primary;

          svg {
            fill: $white;
          }

        }

      }

    }

  }

  &.with-title {

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      left: 10vw;
      height: 85%;
      content: "";
      background: #151c25;
    }

    .title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 1rem;
      padding: 1rem 7rem 1.5rem 1rem;
      margin-left: 10vw;

      .title {
        @include font-size(1.375rem);
        position: relative;
        color: $white;

        &::after {
          position: absolute;
          bottom: -5px;
          left: 0;
          width: 40px;
          height: 3px;
          content: "";
          background-color: rgb(13, 88, 226);
          border-radius: 5px;
        }

      }

      a {
        font-size: 1rem;
        font-weight: 600;
        color: $primary;
        padding: 5px 10px;
        min-height: 35px;
        background-color: $white;
        flex-shrink: 0;

        &:hover{
          color: $white;
          background-color: $primary;
        }

      }

    }

  }

}

@include media-breakpoint-down(lg) {

  .thematic-carousel {

    .splide {
      width: calc(100% + 1rem);
      margin-right: -1rem;

      .splide__arrows {

        &.splide__arrows--ltr {
          right: 1rem;
          width: 90px;
          top: -37px;
        }

        .splide__arrow.splide__arrow--prev,
        .splide__arrow.splide__arrow--next {
          width: 27px;
          height: 27px;
          padding: 4px !important;
        }

      }

    }

    &.with-title {

      .title-wrapper {
        padding: 1rem 5.5rem 1.5rem 1rem;
        margin-left: 0;

        .title {
          font-size: 1rem;
        }

        a {
          font-size: .75rem;
          padding: 3.5px 7px;
          min-height: 27px;
        }

      }

    }

  }

}

@include media-breakpoint-down(md) {

  .thematic-carousel-wrapper {

    h3 {
      padding-right: 3rem;
    }

  }

  .thematic-carousel {

    .splide {

      .splide__arrows {

        &.splide__arrows--ltr {
          right: 0;
        }

      }

    }

    &.with-title {

      &:before {
        display: none;
      }

      .title-wrapper {
        padding: 0 4.25rem 1.5rem 0;
      }

    }


  }

}

#content_player {
  .vidIframe {
    min-width: 1080px;
    min-height: calc(100vh - 250px);
  }
}

.invalid-form-alert {
  color: red;
  font-style: italic;
  font-size: small;
}

.attestation-line {
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: .35rem 1rem;
  background-color: $black;
  margin-bottom: .625rem;
  position: relative;
  font-family: Montserrat, sans-serif;

  > div {
    flex-basis: 30%;
    display: flex;
    align-items: center;
    gap: .75rem;
    font-size: .875rem;

    .label {
      font-size: .9em;
    }

  }

  div.title {
    flex-basis: 100%;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: .5rem;
    padding-right: 3.75rem;
  }

  div.type {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.125rem;
    height: 3.125rem;
    background-color: $body-bg;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.5rem;
    color: $primary;
  }

  .icon-validation {
    font-size: 1.5rem;
    line-height: 1;
  }

  a.file-download {
    display: flex;
    align-items: center;
    gap: .25rem;
    border-bottom: 1px solid $primary;
  }

}

@include media-breakpoint-down(md) {

  .attestation-line {
    padding: 1.25rem;

    > div {
      gap: .35rem;
      font-size: .75rem;
      flex-basis: calc(50% - .5rem);
    }

  }

}

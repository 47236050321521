// -----------------------------------------------------------------------------
// This file contains all @font-face declarations
// -----------------------------------------------------------------------------

@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700;900&family=Oswald:wght@200;400;600;700&display=swap";

@font-face {
  font-family: Guyhoquet;
  src: url("../../assets/font/guy-hoquet/Guyhoquet-Bold.woff") format("woff"), url("../../assets/font/guy-hoquet/Guyhoquet-Bold.woff2") format("woff2");
}

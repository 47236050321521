
.displayBlock{
    display: block !important;
}
.modaltitle{
font-size: 23px ;
line-height: 28px;
}
.provider .modal-footer{
    justify-content: space-between !important;
}
.error{
    border: 1px solid #CC493C;
}
.errorinput{
    border:"1px solid #CC493C" !important;
}
.themeSelect svg{
  height: 20px;
  width: 20px;
  margin-left:10px
}

@media (min-width: 576px){
.provider .modal-dialog {
    max-width: 684px !important;

}
}

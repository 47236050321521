.formation-details {
  display: flex;

  .col-main {
    flex-grow: 1;
    padding: 2.5rem;
    background-color: $black;
  }

  .col-aside {
    width: 25%;
    flex-shrink: 0;
    padding: 1.5rem;
    background-color: rgba($black, .7);
  }

  .btn-open-sessions {
    display: none;
  }

}

@include media-breakpoint-down(xl) {

  .formation-details {

    .col-main {
      padding: 1.5rem;
    }

    .col-aside {
      width: 280px;
    }

  }

}


@include media-breakpoint-down(md) {

  .formation-details {
    flex-direction: column-reverse;
    gap: 1.5rem;

    .col-main {
      padding: 1.25rem;
    }

    .col-aside {
      padding: 1.25rem;
      width: auto;
      background-color: $black
    }

    .btn-open-sessions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      border: 0;
      background-color: transparent;
      color: $white;
      font-weight: bold;
      font-family: Montserrat, sans-serif;
      font-size: 1rem;
      width: 100%;

      &:after {
        display: block;
        content: "";
        width: 36px;
        height: 36px;
        background-image: url('data:image/svg+xml,<svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.516 15.548c.436-.446 1.043-.481 1.576 0L18 19.295l3.908-3.747c.533-.481 1.14-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0s-4.287-4.084-4.695-4.502c-.408-.418-.436-1.17 0-1.615Z" fill="%23CCC"/></svg>');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .sessions-list {
      display: none;
      padding-top: 1rem;

      &.opened {
        display: block;
      }

    }

  }

}

.formation-session {
  display: flex;
  flex-direction: column;
  gap: .125rem;
  border-bottom: 1px dashed rgba($white, .2);
  padding: 1rem 0;

  .title {
    font-family: Montserrat, sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase;
  }

}

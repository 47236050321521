.formation-ua {
  background-color: $black;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  font-family: Montserrat, sans-serif;

  .title {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .resume {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
    font-size: .875rem;

    > div {
      display: flex;
      align-items: center;
      gap: .75rem;

      .label {
        font-size: .9em;
      }

    }

    .resume-duree,
    .resume-score {
      flex-basis: 40%;
    }

    .resume-validation {
      flex-basis: 20%;
      justify-content: flex-end;
    }

  }

  .line {
    background-color: $dark-grey;
    padding: 1rem;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: .875rem;

    > div {
      flex-grow: 1;
      flex-basis: 0;
      display: flex;
      align-items: center;
      gap: .75rem;

      .label {
        font-size: .9em;
      }

    }

    .line-title {
      font-size: 1.1em;
      font-weight: 700;
    }

    .line-cta {

      span {
        display: flex;
        align-items: center;
        gap: .5rem;
        color: $primary;
        border-bottom: 1px solid $primary;
        cursor: pointer;

        img {
          margin: 0 !important;
        }
      }

    }

    .line-validation {
      justify-content: flex-end;
    }

  }

}

@include media-breakpoint-down(lg) {

  .formation-ua {
    padding: 1rem .75rem;

    .line {
      gap: .5rem;
      font-size: .75rem;
    }

  }

}

@include media-breakpoint-down(md) {

  .formation-ua {

    .resume {
      position: relative;
      padding-right: 2rem;

      .resume-duree,
      .resume-score {
        flex-basis: 50%;
      }

      .resume-validation {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        .label {
          display: none;
        }

      }

    }

    .line {
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.125rem;
      padding-right: 2.5rem;

      > div {
        flex-grow: unset;
        flex-basis: 0;
        display: flex;
        align-items: center;
        gap: .75rem;

        .label {
          font-size: .9em;
        }

      }

      .line-validation {
        position: absolute;
        top: 1rem;
        right: 1rem;

        .label {
          display: none;
        }

      }

    }

  }

}

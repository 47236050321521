.liste .table{
  background-color: red !important;
}
.square a {
  padding: 6px;
}
.MuiDataGrid-columnSeparator--sideRight {
  display: none!important;
}
.square a {
  padding: 6px;
}
.MuiDataGrid-columnSeparator--sideRight {
  display: none!important;
}
.selection-cell input[type=checkbox],
.selection-cell-header input[type=checkbox]{
  appearance: none;
  background-color: var(--dark-color);
  width: 18px;
  height: 18px;
  border: 2px solid var(--bs-primary);
  border-radius:2px;
  vertical-align: middle !important;
}
.selection-cell input[type="checkbox"]:checked,
.selection-cell-header input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  background-color: var(--bs-primary);
  background-repeat: no-repeat;
  background-position: center;
}
.selection-cell-header input[type="checkbox"]:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  background-color: var(--bs-primary);
}
.pagination.react-bootstrap-table-page-btns-ul{
  float: right;
  margin-top: 23px;
}
.table.table td a.edit {
  color: #FFC107;
}
table.table td a.delete {
  color: #F44336;
}

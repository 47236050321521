.simple-container {
  min-height: 100vh;
}

.body-wrapper {
  padding: $nav-mobile-height 0 9rem 0;
}

main {
  padding: 1.5rem 0;
}

@include media-breakpoint-down(lg) {

  [class^="container"] {
    padding-right: 1rem;
    padding-left: 1rem;
  }

}

@include media-breakpoint-up(xl) {

  .body-wrapper {
    padding: 0 0 9rem $nav-width;
  }

}

// Récupération du app.css

/* @TODO Faire le ménage et dispatcher tout ca
              _.-^^---....,,---_
           _--                  --_
          <          BOOM!         >)
           \._                   _./
              ```--. . , ; .--'''
                    | |   |
                 .-=||  | |=-.
                 `-=#$%&%$#=-'
                    | ;  :|
           _____.,-#%&$@%#&#~,._____
*/
:root {
  /* CK editor */
  --ck-border-radius: 4px;
  --ck-font-size-base: 14px;
  --ck-color-base-border: hsl(300deg, 1%, 22%) !important;
  --ck-color-base-background: transparent !important;
  --ck-custom-background: hsl(270deg, 1%, 29%) !important;
  --ck-custom-foreground: hsl(255deg, 3%, 18%) !important;
  --ck-custom-border: hsl(300deg, 1%, 22%) !important;
  --ck-custom-white: hsl(0deg, 0%, 100%) !important;
  --ck-color-base-foreground: var(--ck-custom-background) !important;
  --ck-color-focus-border: hsl(208deg, 90%, 62%) !important;
  --ck-color-text: hsl(0deg, 0%, 98%) !important;
  --ck-color-shadow-drop: hsla(0deg, 0%, 0%, .2) !important;
  --ck-color-shadow-inner: hsla(0deg, 0%, 0%, .1) !important;

  /* --ck-color-button-default-background: var(--ck-custom-background) !important; */
  --ck-color-button-default-hover-background: hsl(270deg, 1%, 22%) !important;
  --ck-color-button-default-active-background: hsl(270deg, 2%, 20%) !important;
  --ck-color-button-default-active-shadow: hsl(270deg, 2%, 23%) !important;
  --ck-color-button-default-disabled-background: var(--ck-custom-background) !important;
  --ck-color-button-on-background: var(--ck-custom-foreground) !important;
  --ck-color-button-on-hover-background: hsl(255deg, 4%, 16%) !important;
  --ck-color-button-on-active-background: hsl(255deg, 4%, 14%) !important;
  --ck-color-button-on-active-shadow: hsl(240deg, 3%, 19%) !important;
  --ck-color-button-on-disabled-background: var(--ck-custom-foreground) !important;
  --ck-color-button-action-background: hsl(168deg, 76%, 42%) !important;
  --ck-color-button-action-hover-background: hsl(168deg, 76%, 38%) !important;
  --ck-color-button-action-active-background: hsl(168deg, 76%, 36%) !important;
  --ck-color-button-action-active-shadow: hsl(168deg, 75%, 34%) !important;
  --ck-color-button-action-disabled-background: hsl(168deg, 76%, 42%) !important;
  --ck-color-button-action-text: var(--ck-custom-white) !important;
  --ck-color-button-save: hsl(120deg, 100%, 46%) !important;
  --ck-color-button-cancel: hsl(15deg, 100%, 56%) !important;
  --ck-color-dropdown-panel-background: var(--ck-custom-background) !important;
  --ck-color-dropdown-panel-border: var(--ck-custom-foreground) !important;
  --ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background) !important;
  --ck-color-split-button-hover-border: var(--ck-custom-foreground) !important;
  --ck-color-input-background: var(--ck-custom-background) !important;
  --ck-color-input-border: hsl(257deg, 3%, 43%) !important;
  --ck-color-input-text: hsl(0deg, 0%, 98%) !important;
  --ck-color-input-disabled-background: hsl(255deg, 4%, 21%) !important;
  --ck-color-input-disabled-border: hsl(250deg, 3%, 38%) !important;
  --ck-color-input-disabled-text: hsl(0deg, 0%, 78%) !important;
  --ck-color-labeled-field-label-background: var(--ck-custom-background) !important;
  --ck-color-list-background: var(--ck-custom-background) !important;
  --ck-color-list-button-hover-background: var(--ck-custom-foreground) !important;
  --ck-color-list-button-on-background: hsl(208deg, 88%, 52%) !important;
  --ck-color-list-button-on-text: var(--ck-custom-white) !important;
  --ck-color-panel-background: var(--ck-custom-background) !important;
  --ck-color-panel-border: var(--ck-custom-border) !important;
  --ck-color-toolbar-background: var(--black-color) !important;
  --ck-color-toolbar-border: var(--ck-custom-border) !important;
  --ck-color-tooltip-background: hsl(252deg, 7%, 14%) !important;
  --ck-color-tooltip-text: hsl(0deg, 0%, 93%) !important;
  --ck-color-image-caption-background: hsl(0deg, 0%, 97%) !important;
  --ck-color-image-caption-text: hsl(0deg, 0%, 20%) !important;
  --ck-color-widget-blurred-border: hsl(0deg, 0%, 87%) !important;
  --ck-color-widget-hover-border: hsl(43deg, 100%, 68%) !important;
  --ck-color-widget-editable-focus-background: var(--ck-custom-white) !important;
  --ck-color-link-default: hsl(190deg, 100%, 75%) !important;
}

::-webkit-calendar-picker-indicator {
  background-image: none;
}

input[type="time"] {
  background-image: url("../assets/icons/Time.svg") !important;
  background-repeat: no-repeat;
  background-position: right 7px center;
}

select:disabled {
  color: #4c4c4c !important;
  background-color: #808080 !important;
  background-image: url("../assets/icons/disabledSelect.svg") !important;
}

.secondaryModal {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 12px 18px;
  margin-right: 15px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #006fff;
  background-color: transparent;
  border: 1px solid #006fff;
}

.secondaryModal:disabled {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 12px 18px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #808080;
  background-color: transparent;
  border: 1px solid #808080;
}

button:disabled {
  cursor: not-allowed;
}

.float-right {
  float: right !important;
}

.deleteModal .modal-footer {
  justify-content: space-between !important;
}

.px10 {
  padding: 0 10px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-135 {
  margin-bottom: 135px;
}

.flex-start {
  justify-content: flex-start;
}

.flexgy {
  display: flex;
  justify-content: flex-end;
}

.grecard {
  background-color: #212730 !important;
}

.cardUnit {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #151c25 !important;
  background-clip: border-box;
}

.session1 .cardUnit,
 .session1 .card-body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #212730 !important;
  background-clip: border-box;
}

.session1 .cardUnit:hover .card-body {
  background-color: #212730 !important;
}

.cardUnit:hover .card-body {
  background-color: #151c25 !important;
}

.flex {
  display: flex;
}

.height43 {
  align-items: center;
  height: 43px !important;

  /* display: flex; */
  padding: 0 18px;
}

.btn.height43 {
  display: flex;
  justify-content: space-around;
}

.mt-20 {
  margin-top: 20px;
}

.form-select,
 .form-control {
  font-weight: 600;
  color: #fff !important;
  background-color: #3a4048 !important;
  border: 1px solid #3a4048 !important;
  border-radius: 0 !important;

}

.form-control:focus-visible {
  font-weight: 600;
}

body ::placeholder {
  font-style: italic;
  font-weight: 400;
}

.form-select:focus {
  font-weight: 400 !important;
  border-color: 0 !important;
  box-shadow: none !important;
}

table tr {
  height: 49px;
  font-family: Montserrat, sans-serif !important;
  font-size: 16px;
  font-weight: 600;
}

th {
  height: 49px;
  margin-top: 13px !important;
  margin-bottom: 14px !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  vertical-align: middle !important;
}

td {
  margin-top: 13px !important;
  margin-bottom: 14px !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  vertical-align: middle !important;

}

.table > :not(caption) > * > * {
  padding: 0 10px;
}

.sub-nav li.nav-item .nav_link {
  margin-bottom: 0;
}

.App {
  text-align: center;
}

.btn {
  border-radius: 0 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.disable::before,
 .disable h2::after {
  content: none !important;
}

.table-dark {
  --bs-table-bg: var(--dark-color) !important;
  --bs-table-hover-bg: #59a1ff !important;
}

.tbody tr th {
  padding-top: 16px;
}

.simpletext img {
  width: 18px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #282c34;
}

.info-inline .list-inline-item {
  position: relative;
}

.info-inline li.list-inline-item::before {
  position: absolute;
  top: 8px;
  right: 5px;
  width: 2px;
  height: 12px;
  content: "";
  background: #6c6c6c;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body ::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  display: none;
  border-radius: 10px;
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  display: none;
  background: #333;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  display: block;
  background: #333;
}

.searchTitle h4 {
  padding-left: 0;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  color: #fff;
  text-transform: uppercase;
}

.search-title span {
  color: var(--primary-color);
}

[type="search"]::placeholder {
  color: hsl(0deg, 0%, 80%);
}

[type="search"] {
  padding-right: 5px;
  background: url("../assets/icons/Search.svg");
  background-repeat: no-repeat;

  /* background-size: 7%; */
  background-position: 98%;
}

[type="search"]::-webkit-search-cancel-button {
  display: none !important;
}

.form-check .form-check-input {
  width: 18px;
  height: 18px;
  margin-bottom: 12px !important;
  background-color: var(--secondary-color) !important;
  border: 2px solid #006fff !important;
  border-radius: 0 !important;
}

.form-check .form-check-input:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.breadcrumb-item a {
  font-size: 14px;
  font-weight: 400;
  color: var(--white-color);
}

.breadcrumb-item.active {
  font-weight: 600;
  color: var(--white-color) !important;
}

.breadcrumb-item a:hover {
  color: var(--white-color);
}

.btn-check:focus + .btn,
 .btn:focus {
  box-shadow: none !important;
}

.headupdate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nopad {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.round {
  width: 20px !important;
  height: 20px !important;
  background-color: #006fff !important;
  border-radius: 50% !important;
}

.fade.toast.show {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 9999;
}

.Csuccess {
  background: rgb(227, 247, 225) !important;
}

.Cerror {
  background: rgb(247, 225, 230) !important;
}

.Cerror-jago {
  color: #fff !important;
  background: red !important;
}

.Csuccess-jago {
  color: #fff !important;
  background: green !important;
}

@media only screen and (max-width: 992px) {
  .card .card-title {
    font-size: 16px !important;
  }

  /* .search-title {
      font-size: 26px;
  } */
  .body-details .search-title {
    font-size: 16px !important;
  }

  @media screen and (max-width: 425px) {
    .form-check-input {
      width: 27px !important;
      height: 18px !important;
    }

    .flexgy {
      flex-direction: column;
    }

    .flexgy .flex {
      justify-content: space-evenly !important;
    }
  }

}

@media only screen and (max-width: 578px) {
  .flexgy {
    flex-direction: column;
  }

  .flexgy .flex {
    justify-content: space-evenly !important;
  }

  .myxs {
    margin: 10px 0 !important;
  }
}

@media only screen and (max-width: 425px) {
}

.form-control:hover {
  border: 1px solid #59a1ff !important;
  box-shadow: none !important;
}

.form-control:focus {
  border: 1px solid #006fff !important;
  box-shadow: none !important;
}

.errorinput {
  border: 1px solid #cc493c !important;
}

.videomodal .textemodal {
  font-size: 18px;
  font-weight: 200;
}

.deleteModal .modal-dialog {
  max-width: fit-content !important;
}

.btn-close:focus {
  box-shadow: none !important;
}

.simpletext {
  font-family: Montserrat, sans-serif !important;

  /* background-color: #212730!important; */
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.simpletext ul li {
  position: relative;
  margin: .5rem 0;
  list-style: none;
}

.simpletext ul li::before {
  position: absolute;
  top: 2px;
  left: -33px;
  display: inline-block;
  width: 18px;
  height: 18px;
  content: "";
  background-image: url("../assets/icons/Plus.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.no-padding p {
  padding-left: 0 !important;
  font-family: Montserrat, sans-serif !important;
  font-weight: 700 !important;
  line-height: 22px !important;
}

.no-padding .simpletext p {
  display: flex;
  padding-left: 0 !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
}

span.nameAgence {
  color: white;
  text-transform: lowercase;
}

.calendar .form-control {
  width: auto;
}

.calendar {
  text-align: right;
}

table .form-switch {
  padding-left: 1.5em !important;
}

.no-style div {
  height: auto !important;
  min-height: auto !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.no-style svg {
  width: 25px;
  height: 25px;
  padding-top: 5px;

  /* background: red; */
  fill: #006fff;
}

.no-style ul {
  color: white;
  background: #3a4048;
}

.pHieT .selected {
  cursor: pointer;
  background-color: #1e2e45;
}

.no-style input {
  color: white;
}

.react-daterange-picker__button {
  filter: invert(31%) sepia(38%) saturate(7483%) hue-rotate(204deg) brightness(92%) contrast(103%);
}

.react-daterange-picker__button:hover {
  filter: invert(31%) sepia(38%) saturate(7483%) hue-rotate(204deg) brightness(92%) contrast(103%);
}

.react-daterange-picker .react-daterange-picker{
  &__wrapper {
    border: none;
  }

  &__inputGroup {
    text-align: center;
  }
}

.react-daterange-picker__calendar .react-calendar {
  color: $white;
  background: $dark-grey;

  .react-calendar__tile {
    color: $white;
  }

  .react-calendar__tile:hover{
    color: $white;
  }

  .react-calendar__navigation__arrow {
    color: $white;
  }

  .react-calendar__navigation .react-calendar__navigation__arrow:hover,
  .react-calendar__navigation .react-calendar__navigation__arrow:active,
  .react-calendar__navigation .react-calendar__navigation__arrow:focus,
  .react-calendar__navigation .react-calendar__navigation__label:hover,
  .react-calendar__navigation .react-calendar__navigation__label:active,
  .react-calendar__navigation .react-calendar__navigation__label:focus {
    background-color: $primary;
  }

  .react-calendar .react-calendar__navigation .react-calendar__navigation__label:hover,
  .react-calendar__navigation .react-calendar__navigation__label:hover,
  .react-calendar__navigation .react-calendar__navigation__label:active,
  .react-calendar__navigation .react-calendar__navigation__label:focus {
    color: $white;
  }

  .react-calendar__tile:hover:not(.react-calendar__tile--active),
  .react-calendar__tile--hover:not(.react-calendar__tile--active), {
    color: $primary;
  }

  .react-calendar__viewContainer .react-calendar__tile--active.react-calendar__month-view__days__day--weekend {
    color: $dark-grey;
  }

  .react-calendar__tile.react-calendar__month-view__days__day--weekend{
    color: $primary;
  }

}

.react-date-picker,
.react-datetime-picker {
  color: $input-color;
  background-color: $input-bg;

  .react-date-picker__wrapper,
  .react-datetime-picker__wrapper {
    border: 0;
  }

  button:disabled,
  .react-calendar__tile:disabled {
    background-color: #999;
  }
}

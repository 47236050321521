.cardSession{
   background-color: #1E2E45 ;
   padding:20px 35px;
   /* margin-right:20px;
  min-width: 244px;*/
   min-height:97px;
}

.title16{
   font-size: 16px;
   text-align: center;
}

.title23{
   font-size: 23px;
   text-align: center;
   font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
color: #ffffff;
}
.select{
   background-image: url('../../../../assets/icons/ChevronDown.svg') !important;
   background-size: 1.25rem;
   background-repeat: no-repeat;
   background-position: center right;
}


::-webkit-calendar-picker-indicator {
  background-image: url('../../../../assets/icons/Calendar.svg') !important;
  background-position: right center;
  background-repeat: no-repeat;
}

.dashboard .trained-container{
  .legend {
    .green {
      width: 30px;
      height: 10px;
      margin-right: 15px;
      background: #3ea279;
    }
    .white {
      width: 30px;
      height: 10px;
      margin-right: 15px;
      background: white;
    }
  }
}

.dashboard .chartInner {
  top: 25px;
  right: 20px;
  bottom: 14px;
  left: 20px;
}

.export-button-right {
  margin-left: auto;
}


.cards-grid {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 1rem;
}

@include media-breakpoint-down(xl) {

  .cards-grid {

    .card {
      font-size: 0.9375rem;

      .card-title {
        font-size: 0.8125rem !important;
      }

    }

  }

}

@include media-breakpoint-down(md) {

  .cards-grid {

    .card {

      .card-title {
        font-size: 0.6875rem !important;
      }

    }

  }

}

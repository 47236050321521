.square {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  padding: 0;
  cursor: pointer;

  i {
    pointer-events: none;
  }
}

.square button {
  padding: 6px 10px;
}

.square label {
  padding: 6px 10px;
  cursor: pointer;
}

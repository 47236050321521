.formation-header {
  background-color: $black;
  padding: 1.5rem;
  margin-bottom: 1.75rem;

  .chartContainer {
    width: 6.25rem;
    margin: 0;
  }

  .progress-wrapper {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .cards-grid {

    .card {
      height: 100%;
      background-color: $dark-grey;
    }

  }

}

@include media-breakpoint-down(xl) {

  .formation-header {

    .progress-wrapper {
      border-top: 1px solid rgba($white, .15);
      padding-top: .75rem;
      margin-top: .75rem;
    }

  }

}

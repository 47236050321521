.dt-container {

  .dt-table-content{
    z-index: 10;
  }

  .dt-actions-filters.with-filters .dt-filters > * {
    flex-basis: 240px;
  }

  .dt-table-content .dt-actions-groups {
    background-color: $body-bg;

    &.opened {
      z-index: 10;
    }

    .actions {
      display: flex;
    }

    .actions > * {
      margin-right: .5em;
    }
  }

  .element-edit {
    position: relative;
    width: 100%;

    > * {
      position: relative;
      z-index: 1;
    }

    &::before {
      position: absolute;
      top: -5px;
      right: -10px;
      bottom: -5px;
      left: -10px;
      z-index: 0;
      display: block;
      cursor: pointer;
      content: "";
      background-color: rgba($black, .05);
      border-radius: 5px;
      opacity: 0;
      transition: all ease .3s;
    }

    &:hover {

      &::before {
        opacity: 1;
      }

    }

  }

  .element-edit-edition {
    display: flex;
    flex-grow: 1;

    > div {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }

    .form-group {
      flex-grow: 1;
      margin: 0;

      .form-label {
        display: none;
      }

    }

  }
}

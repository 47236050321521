.cardUnit {

  .card-title {
    width: 95%;
    color: var(--bs-primary);
    font-size: 20px;
  }

  .icon {
    padding: 7px;
    background-color: #212730;
    position: absolute;
    z-index: 999;
    width: 32px;
    height: 32px;
    right: 0;
    top: 0;
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    font-weight: bolder;
    line-height: 0;

    svg {
      float: none;
      color: white;
    }

  }

}

@include media-breakpoint-down(lg) {

  .cardUnit {
    margin-bottom: 35px;
  }

}

.paginator-container .pagination li {
  &:not(:first-child) .page-link {
    margin-left: $pagination-margin-start;
  }

  @if $pagination-margin-start == calc(#{$pagination-border-width} * -1) {
    &:first-child {
      .page-link {
        @include border-start-radius(var(--#{$prefix}pagination-border-radius));
      }
    }

    &:last-child {
      .page-link {
        @include border-end-radius(var(--#{$prefix}pagination-border-radius));
      }
    }
  } @else {
    // Add border-radius to all pageLinks in case they have left margin
    .page-link {
      @include border-radius(var(--#{$prefix}pagination-border-radius));
    }
  }

  a {
    position: relative;
    display: block;
    padding: var(--#{$prefix}pagination-padding-y) var(--#{$prefix}pagination-padding-x);
    @include font-size(var(--#{$prefix}pagination-font-size));
    color: var(--#{$prefix}pagination-color);
    text-decoration: if($link-decoration == none, null, none);
    background-color: var(--#{$prefix}pagination-bg);
    border: var(--#{$prefix}pagination-border-width) solid var(--#{$prefix}pagination-border-color);
    @include transition($pagination-transition);

    &:hover {
      z-index: 2;
      color: var(--#{$prefix}pagination-hover-color);
      text-decoration: if($link-hover-decoration == underline, none, null);
      background-color: var(--#{$prefix}pagination-hover-bg);
      border-color: var(--#{$prefix}pagination-hover-border-color);
    }

    &:focus {
      z-index: 3;
      color: var(--#{$prefix}pagination-focus-color);
      background-color: var(--#{$prefix}pagination-focus-bg);
      outline: $pagination-focus-outline;
      box-shadow: var(--#{$prefix}pagination-focus-box-shadow);
    }
  }

  &.active a,
  .active a > & {
    color: var(--#{$prefix}pagination-active-color);
    @include gradient-bg(var(--#{$prefix}pagination-active-bg));
    border-color: var(--#{$prefix}pagination-active-border-color);
  }

  &.disabled a,
  .disabled a > & {
    color: var(--#{$prefix}pagination-disabled-color);
    pointer-events: none;
    background-color: var(--#{$prefix}pagination-disabled-bg);
    border-color: var(--#{$prefix}pagination-disabled-border-color);
  }

}

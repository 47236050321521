.categories-navigator {
  --head-height: 3.5rem;
  position: fixed;
  right: 0;
  bottom: 0;
  left: $nav-width;
  z-index: $zindex-fixed;
  color: $white;
  transform: translateY(calc(100% - var(--head-height)));
  transition: transform ease .5s;
  max-height:100%;
  overflow-y:scroll;

  .head {
    position: relative;
    z-index: 0;
    height: var(--head-height);
    padding: 0 1.875rem;
    display: flex;
    justify-content: flex-end;

    button {
      background-color: $primary;
      color: $white;
      border: 0;
      padding: .5rem 1rem;
      display: flex;
      align-items: center;
      box-shadow: 0 -2px 14px rgba($black, .5);
      font-size: .8125rem;
      gap: .5rem;

      i {
        font-size: 1.125rem;
        line-height: 1;
      }

      i.uil-multiply {
        display: none;
      }

    }

  }

  .body {
    position: relative;
    z-index: 1;
    background-color: $primary;
    padding: 2rem 2.75rem;

    > ul {
      list-style-type: none;
      margin: 0 auto;
      padding: 0;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 0 2.5rem;
      width: 1200px;
      max-width: 100%;

      > li {
        flex-basis: calc(50% - 1.25rem);
        border-bottom: 1px solid rgba($white, .2);
        padding: 1rem 0;

        > a {
          display: flex;
          align-items: center;
          gap: .25rem;
          font-family: Guyhoquet, sans-serif;
          font-size: 1rem;

          &:before {
            display: block;
            content: "";
            width: 11px;
            height: 11px;
            background-image: url('data:image/svg+xml,<svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.142.5v4.112h3.923v2.33H7.142v4.29H4.68V.5h2.462Zm-4.8 6.442H0v-2.33h3.559l-1.217 2.33Z" fill="%23fff"/></svg>');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }

          &:after {
            display: block;
            content: "";
            width: 14px;
            height: 14px;
            background-image: url('data:image/svg+xml,<svg viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.47 2.112 12.825.5 7.35 5.938 1.911.5.261 2.112l5.476 5.476 1.613 1.65L9 7.588l5.477-5.476h-.008Z" fill="%23F8F9FA"/><path d="M8.647 7.234 7.354 8.526l-1.26-1.288-.003-.004L.972 2.117l.935-.914 5.09 5.088.352.352.353-.35 5.126-5.09.935.916-5.116 5.115Z" stroke="%23fff" stroke-opacity=".2"/></svg>');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin-left: auto;
          }

          &.opened {

            &:after {
              transform: rotate(180deg);
            }

          }

        }

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0 1rem;

          li {
            margin-bottom: .5rem;

            &:first-child {
              margin-top: 1rem;
            }

            a {
              font-size: 1rem;
            }

          }

        }

      }

      a {
        color: $white;
      }

    }

  }

  &.opened {
    transform: none;

    .head {

      button {

        i.uil-multiply {
          display: block;
        }

        i.uil-apps {
          display: none;
        }

      }

    }

  }

}

@include media-breakpoint-down(xl) {

  .categories-navigator {
    left: 0;
  }

}

@include media-breakpoint-down(lg) {

  .categories-navigator {
    --head-height: 2.5rem;

    .body {
      padding: 1rem 1.875rem;
    }

  }

}

@include media-breakpoint-down(sm) {

  .categories-navigator {

    .body {
      padding: 1rem 1.25rem;

      > ul {
        flex-direction: column;
        align-items: stretch;

        > li {
          flex-basis: unset;
        }

      }

    }

  }

}

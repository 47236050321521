
 @media (min-width: 992px)
{
    .profil .modal-lg, .modal-xl {
    max-width: 900px !important;
}}
.popup .form-check-input{
   margin-top:3px !important;
}
.popup .ines{
    background-color: green;
}
.popup .modal-header {
    color : white;
}
.popup .modal-footer {
    border-top: 0;
}
.popup .modal-header{
    border-bottom: 0;
}
.popup .label-popup{
    size:16px;
}
.popup .label-popup-taux .search-title{
    font-size: 18px;
}
.popup .modal-body .form-check-input{
    height: 20px;
    width: 20px;
    border: 2px solid var(--bs-primary);
    background-color: var(--black-color);
}
.popup .modal-body .form-control .drop{
    border: var(--input-color);
}
.popup .modal-header .btn-close{
    background: transparent url('../../assets/icons/closeModal.svg') no-repeat
}
.popup .btn-check:active+.btn-primary,
.popup .btn-check:checked+.btn-primary,
.popup .btn-primary.active,
.popup .btn-primary:active,
.popup .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #3A4048;
    border-color: #3A4048;
}
.popup .modal-dialog .dropdown button, .popup .modal-dialog .input{
    width: 100%;
    text-align: left;
}
.popup .dropdown-menu {
    background-color: var(--input-color);
    width: 100%;
}
.popup .dropdown-menu .dropdown-item{
    color: var(--bs-white);
}
.popup .dropdown-item:focus {
    color: var(--black-color);

}
.popup .form-check-label{
    color: white;
}
.popup .dropdown-toggle::after {
    position: absolute;
    right: 6px;
    top: 14px;
    right: 7px;
    top: 7px;
    width: 20px;
    height: 20px;
    vertical-align: 0.255em;
    content: "";
    background: url(../../assets/icons/Dropdown-popup.png);
    border: 0;
    /* right: 0; */
}
.popup .btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}
.popup .dropdown-item:focus, .popup .dropdown-item:hover {
    background-color: var(--black-color);
}
 .popup .modal-body input[type="date"]{
    color: #fff;
    background-color: var(--input-color);
    border-color: var(--input-color);
}

.popup .vidIframe {
    min-width: 1080px;
    min-height: calc(100vh - 250px);
}
.popup .special-width-popup .modal-dialog {
    width: 100%!important;
    max-width: 50%;
}
.popup .filter .modal-footer {
    justify-content: space-between;
}
@media only screen and (max-width: 1200px) {
    .popup .ines{
        background-color: red;
    }
    .popup .special-width-popup .modal-dialog {
        width: 95%;
    }
}
